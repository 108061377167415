'use client';
import { useSignals } from '@preact/signals-react/runtime';
import { useRouter, usePathname } from 'next/navigation';
import { PropsWithChildren } from 'react';
import { sToken } from './signals/token';
import { Header } from './components/Header';

export default function Template({ children }: PropsWithChildren) {
  useSignals();

  const pathname = usePathname();
  const router = useRouter();
  if (typeof window === 'undefined') return null;

  if (pathname !== '/login' && pathname !== '/signup') {
    !sToken.value && router.push('/login');
  }
  return (
    <main className='flex flex-col gap-4'>
      <div className='px-4 bg-white w-full sticky top-0 shadow-[1px_1px_8px_rgba(0,0,0,0.25)] z-[100] pt-4 pb-2'>
        <div className='max-w-[1684px] mx-auto flex flex-col gap-4'>
          <Header />
          {/* <TopNav className='mb-2' /> */}
        </div>
      </div>
      <div className='w-full'>
        <div className='max-w-[1684px] mx-auto flex flex-col gap-4 overflow-auto'>
          {children}
        </div>
      </div>
    </main>
  );
}
